<template>
  <div class="case-conent">
    <div class="hd">
      <div class="back"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini" plain round>返回</el-button></div>
      学习时间轴
    </div>
    <!--学历案日志列表-->
    <div class="table-content">
      <div class="wrongQuestionDetails">
        <div class="timeline-top">
          <img src="../../assets/images/timeline-top.png" alt="">
          <h2>课程名称：《{{resourceName}}》</h2>
        </div>
        <div class="timeline-content">
          <el-timeline  class="timeline">
            <el-timeline-item
                v-for="(activity, index) in qureyTimeLineData"
                :key="index"
                :icon="activity.icon"
                type="primary"
                color="#0090DA"
                size="large"
                :timestamp="activity.createTime"
            >
              {{ activity.name }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import {qureyTimeLineObj} from '@/api/journal'   //路径
export default {
  name: '',
  data() {
    return {
      resourceName:'',
      qureyTimeLineObj:[],
      qureyTimeLineData:[],
      activities: [
        {
          content: '做本章实战演练',
          timestamp: '2018-04-12 20:46',
          size: 'large',
        },
        {
          content: '做一颗一练A套',
          timestamp: '2018-04-03 20:46',

        },
        {
          content: '查看PPT',
          timestamp: '2018-04-03 20:46',
          size: 'large',
        },
        {
          content: '查看class课件：《屈原》',
          timestamp: '2018-04-03 20:46',
          type: 'primary',
          hollow: true,
        },
        {
          content: '登录系统',
          timestamp: '2018-04-03 20:46',
        },
      ],
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.bookId = this.$route.query.bookId;
    this.resourceName = this.$route.query.resourceName;
    this.stuId = this.$route.query.stu;
    this.folderId = this.$route.query.folderId;
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.qureyTimeLine()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    },
    //查询列表
    qureyTimeLine() {
      console.log(this.clickFlag,'8383848448484')
      let obj = {
        bookId:this.bookId,
        folderId:this.folderId
      }
      qureyTimeLineObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        this.qureyTimeLineData = res.data
      })
    },


  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
  .back-btn {
    border: 1px solid #c4e7ff;padding: 0px 10px; height:32px;line-height:32px; background-color: #37AEFF; color:#c4e7ff
  }
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
    position: relative;
    .back {
      position: absolute;
      justify-content: flex-start;
      left: 20px;
    }
  }
  .table-content {
    width: 98%;
    height: 94%;
    margin: 10px auto;
    .search {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

      }
    }
    .table {
      height: 60%;
    }
    .wrongQuestionDetails {
      width: 100%;
      height: 99%;
      overflow-y: auto;
      margin: auto;
    }
    .page {
      height: 40px;
      background-color: #238bff;
    }
  }
  .timeline-top {
    width: 80%;
    margin: auto;
    padding-top: 50px;
    box-sizing: border-box;
    text-align: center;
    img {
      width: 146px;
      margin: auto;
    }
    h2 {
      text-align: center;
    }
  }
  .timeline-content {
    width: 96%;
    margin: 10px auto;
    height: 450px;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    .timeline {
      box-sizing: border-box;padding-left: 110px
    }
  }
}

</style>

//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 查询学历案日志列表：
export function qureyJournalObj(obj) {
    return request({
        url: '/study/students/qureyJournal',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询学生学历案详情 ：
export function qureyJournalDetailObj(obj) {
    return request({
        url: `/study/students/qureyJournalDetail/${obj.bookId}/${obj.folderId}/${obj.id}/${obj.type}`,    // url = base url + request url
        method: 'get',
    })
}
// 查询学生学历案时间线 ：
export function qureyTimeLineObj(obj) {
    return request({
        url: `/study/students/queryTimeLine/${obj.bookId}/${obj.folderId}`,    // url = base url + request url
        method: 'get',
    })
}

